import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import ProHeadshot from "components/common/ProHeadshot";
import Image from "components/common/Image";
import {
  getLeagueSeriesUrl,
  getStaticPlayer2Url,
  getTeamUrl,
  getPlayerCountryFlag,
  getRiotAssetsContext,
} from "@outplayed/riot-assets";

import { getRoleImg } from "lib/role-helpers";
import { normalizeLeagueUrl } from "lib/general-helpers";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { ReactComponent as UGGLogo } from "svg/ugg-logo.svg";
import { ReactComponent as LinkIcon } from "svg/link-icon.svg";
import brushStrokeURL from "svg/yellow-brush-stroke.svg";

function getLanePositionText(role) {
  switch (role) {
    case "top":
      return "Top Laner";
    case "jungle":
      return "Jungler";
    case "mid":
    case "middle":
      return "Mid Laner";
    case "support":
      return "support";
    case "adc":
    case "bot":
    case "bottom":
      return "ADC";
    default:
      return "";
  }
}

function formatTagText(tag) {
  if (tag.match(/^(Top) .+ (pro)?/)) {
    const split = tag.split(" ");
    return split.slice(0, split.length - 1).join(" ");
  } else {
    return tag;
  }
}

function filterTags(tags) {
  const newTagOrder = [];
  for (const tag of tags) {
    if (tag.match("Worlds Participant")) {
      newTagOrder.unshift(tag);
    } else {
      newTagOrder.push(tag);
    }
  }
  return newTagOrder;
}

const ProHeader = (props) => {
  const { proJSON, proAdditional, proTags, rankChampions } = props;
  const { getWorkingPatch, getChampionName } = getRiotAssetsContext();

  const [windowBreakpoint] = useGlobal("responsive");
  const { nation } = proAdditional || {};
  const {
    current_ign,
    current_team,
    league,
    official_name,
    normalized_name,
    region_id,
    main_role,
    riot_user_name,
    riot_tag_line,
  } = proJSON;

  const tagCount = windowBreakpoint === "MOBILE_SMALL" ? 2 : 5;
  const { basicChampionPerformances } = rankChampions || {};
  const top3RankChampions = basicChampionPerformances.slice(0, 3).map(({ championId }) => getChampionName(championId));
  const currentSeason = getWorkingPatch().split(".")[0];
  const uggProfileURL = `https://u.gg${getProfileOverviewUrl(region_id, riot_user_name, riot_tag_line)}`;
  const playerBlurb = `
    ${proTags.includes("World Champion") ? "World Champion" : ""} ${
    proTags.includes("All Star") ? "All Star" : ""
  } ${league} ${getLanePositionText(main_role)} for ${current_team}.
    ${official_name}'s best S${currentSeason} champions were ${top3RankChampions[0]}, ${top3RankChampions[1]}, and ${
    top3RankChampions[2]
  }.
  `;
  const tags = filterTags(proTags);

  return (
    <div className="pro-page_header">
      <Helmet>
        <meta name="description" content={playerBlurb} />
      </Helmet>
      <div className="player-headshot">
        <ProHeadshot src={getStaticPlayer2Url(normalized_name)} alt={official_name} />
      </div>
      <div className="player-info">
        <div className="player-info_1">
          <h1 className="player-name">{official_name}</h1>
          <div className="player-tags">
            {tags.slice(0, tagCount).map((tag, index) => {
              let tagClassName = classnames("tag");
              let isSpecialTag = false;
              if (tag.match("Worlds Participant")) {
                tagClassName = classnames("tag", "special-tag");
                isSpecialTag = true;
              }
              return (
                <div key={index} className={tagClassName}>
                  {isSpecialTag && <img className="special-tag_background" src={brushStrokeURL} alt="Special Tag" />}
                  <span>{formatTagText(tag)}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="player-info_2">
          <div className="team">
            <Image className="max-w-none" src={getTeamUrl(current_team)} alt={current_team} invisible />
            <div className="team-name">{current_team}</div>
          </div>
          <div className="league">
            <img src={getLeagueSeriesUrl(normalizeLeagueUrl(league))} alt={league} />
          </div>
          <div className="role">
            <div>{getRoleImg(main_role)}</div>
          </div>
          {nation && (
            <div className="country">
              <img src={getPlayerCountryFlag(nation)} alt={nation} />
            </div>
          )}
        </div>
      </div>
      <a className="profile-link" target="_blank" rel="noreferrer" href={uggProfileURL}>
        <UGGLogo className="ugg-logo" />
        <div className="link-text">Profile</div>
        <LinkIcon className="link-icon" />
      </a>
      <div className="player-blurb">{playerBlurb}</div>
    </div>
  );
};

export default ProHeader;
